.pulse {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 16px;
}

.pulseCircle {
  background: #ffa50090;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px #ffa50060;
  }

  100% {
    box-shadow: 0 0 0 8px rgba(255, 165, 0, 0);
  }
}